//* Styles
import { useRef } from 'react';
import CollapseContainerStyle from './style';
import gsap from 'gsap/all';

//* Component

const CollapseContainer = ({ children, multiple = true, openType }) => {
	//! Refs
	const collapseContainerRef = useRef();
	const prevActive = useRef(null);

	function collapseClick(ev) {
		const itemElement = ev.target.closest('#collapse-item');
		const openCloseButton = openType == 'button' ? ev.target.closest('#open-close-button') : ev.target.closest('#collapse-title');

		if (itemElement && openCloseButton) {
			const statusItem = itemElement.getAttribute('status');

			const prevStatus = prevActive.current ? prevActive.current.parentElement.getAttribute('status') : null;

			if (prevActive.current && prevStatus == 'open' && multiple) {
				gsap.to(prevActive.current, { duration: 0.3, height: 0, ease: 'power2.out' });

				prevActive.current.parentElement.setAttribute('status', 'close');
				openType == 'button' ? prevActive.current.parentElement.querySelector('#open-close-button')?.children[0]?.classList.remove('active') : null;
				prevActive.current = null;
			}

			if (statusItem && statusItem == 'close') {
				itemElement.setAttribute('status', 'open');
				openType == 'button' ? openCloseButton.children[0]?.classList.add('active') : null;

				gsap.to(itemElement.children[1], { duration: 0.3, height: 'auto', ease: 'power2.out' });
				prevActive.current = itemElement.children[1];
			} else {
				itemElement.setAttribute('status', 'close');
				openType == 'button' ? openCloseButton.children[0]?.classList.remove('active') : null;

				gsap.to(itemElement.children[1], { duration: 0.3, height: 0, ease: 'power2.out' });
				prevActive.current = null;
			}
		}
	}

	return (
		<CollapseContainerStyle
			ref={collapseContainerRef}
			onClick={collapseClick}>
			{children}
		</CollapseContainerStyle>
	);
};

export default CollapseContainer;
