import styled from 'styled-components';

const CollapseItemStyle = styled.div`
	--padTB: var(--sp5x);
	--padLR: var(--sp5x);
	--marginB: var(--sp3x);
	--borderRadius: var(--sp4x);

	background-color: var(--white);
	padding: var(--padTB) var(--padLR);
	border-radius: var(--borderRadius);
	margin-bottom: var(--marginB);

	.collapse-description {
		overflow: hidden;
		height: 0;
	}

	//! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--padTB: var(--sp4x);
		--padLR: var(--sp4x);
	}

	//! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
	}

	//! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--padTB: var(--sp3x);
		--padLR: var(--sp3x);
		--marginB: var(--sp2x);
		--borderRadius: var(--sp3x);
	}

	//! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--padTB: var(--sp2-5x);
		--padLR: var(--sp2-5x);
		--borderRadius: var(--sp2-5x);
	}

	//! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--padLR: var(--sp2x);
		--marginB: var(--sp1x);
	}

	//! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--padTB: var(--sp2x);
		--marginB: var(--sp1-5x);
		--borderRadius: var(--sp2x);
	}
`;

export default CollapseItemStyle;
