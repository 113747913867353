//* Styles
import CollapseItemStyle from './style';

//* Component

const CollapseItem = ({ componentTitle, componentDescription }) => {
	return (
		<CollapseItemStyle>
			<div
				id={'collapse-item'}
				status={'close'}>
				<div id='collapse-title'>{componentTitle}</div>
				<div className='collapse-description'>{componentDescription}</div>
			</div>
		</CollapseItemStyle>
	);
};

export default CollapseItem;
