//* Styles
import OpenCloseSvgStyle from './style';

//* Component
import CollapseOpenCloseButton from '@/components/Collapse/CollapseOpenCloseButton';

const OpenCloseSvg = () => {
	return (
		<CollapseOpenCloseButton>
			<OpenCloseSvgStyle>
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<g id='plus'>
						<path
							id='union'
							d='M6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6Z'
							fill='white'
							style={{ fillOpacity: 1 }}
						/>
					</g>

					<g>
						<path
							id='union-2'
							d='M13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6V18Z'
							fill='white'
							style={{ fillOpacity: 1 }}
						/>
					</g>
				</svg>
			</OpenCloseSvgStyle>
		</CollapseOpenCloseButton>
	);
};

export default OpenCloseSvg;
