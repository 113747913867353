import styled from 'styled-components';

const OpenCloseStyle = styled.div`
	--radiusSvg: var(--sp0-5x);

	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		overflow: hidden;
		border-radius: var(--radiusSvg);
		background-color: var(--backgroundColor);
		transition: var(--trTime) ease-out;
		cursor: pointer;

		g,
		path {
			transition: var(--trTime) ease-out;
			fill: var(--blue1000);
		}

		#union-2 {
			transform: rotate(0deg);
			transition: var(--trTime) ease-out;
			transform-origin: center center;
		}
	}

	&.active {
		svg {
			background-color: var(--blue1000);

			g,
			path {
				fill: var(--white);
			}

			#union-2 {
				transform: rotate(90deg);
			}
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
	}
	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
	}
	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
	}
	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
	}
`;
export default OpenCloseStyle;
